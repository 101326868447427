[data-alert-bar] {
  position: relative;
  left: 50%;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  width: var(--alert-bar-width, 100vw);
  padding: 5px;
  text-align: center;
  background: $spectrum-quaternary;
  transform: translateX(-50vw);
  transform: translateX(calc(var(--alert-bar-width, 100vw) / -2));

  p {
    @include font-body(bold);

    font-size: 1.1em;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}
