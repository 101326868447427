// Grids
// -----
.row {
  // Only clearfix for rows tablet and above
  @include respond($break40) {
    @include clearfix;
  }
}

// If used 1 <ul>
ul.row {
  @include list-unstyled;
}

// For breaking clearfix if need be
.row.unfix {
  &::after {
    display: none;
  }
}

.row.padded {
  @include respond($break40) {
    margin-right: $gridWrapper;
    margin-left: $gridWrapper;
  }
}

.row.padded-20 {
  @include respond($break40) {
    margin-right: $gridWrapper20;
    margin-left: $gridWrapper20;
  }
}

.row.padded-40 {
  @include respond($break40) {
    margin-right: $gridWrapper40;
    margin-left: $gridWrapper40;
  }
}

.row.padded-50 {
  @include respond($break40) {
    margin-right: $gridWrapper50;
    margin-left: $gridWrapper50;
  }
}

// TODO: Refactor as sub class that's only appended at certain media
// queries instead of a mixin
@mixin columnPrototype {
  box-sizing: border-box;
  float: none;
  width: 100%;

  @include respond($break40) {
    float: left;
  }

  .padded & {
    @include respond($break40) {
      padding-right: $gridPadding;
      padding-left: $gridPadding;
    }
  }

  .padded-20 & {
    @include respond($break40) {
      padding-right: $gridPadding20;
      padding-left: $gridPadding20;
    }
  }

  .padded-40 & {
    @include respond($break40) {
      padding-right: $gridPadding40;
      padding-left: $gridPadding40;
    }
  }

  .padded-50 & {
    @include respond($break40) {
      padding-right: $gridPadding50;
      padding-left: $gridPadding50;
    }
  }

  .padded-v & {
    padding-bottom: 6%;

    @include respond($break40) {
      padding-bottom: 2%;
    }
  }
}

// Fixed Width Grids
// --------------------------------------------------------
.row-fixed {
  @include clearfix;

  &.padded {
    margin-right: -0.35rem;
    margin-left: -0.35rem;
  }
}

.fixed-two-thirds {
  @include columnPrototype;

  @include respond($break40) {
    width: calc(100% - 23.333rem);

    .padded-40 & {
      width: calc(100% - 26.875rem);
    }
  }
}

.fixed-one-third {
  @include columnPrototype;

  @include respond($break40) {
    width: 23.333rem;

    &.padded-left {
      padding-left: $gridPadding * 2;
    }

    .padded-40 & {
      width: 26.875rem;
    }
  }
}

.fixed-two-thirds + .fixed-one-third,
.fixed-one-third + .fixed-two-thirds {
  margin-top: 1.25em;

  @include respond($break40) {
    margin-top: 0;
  }
}

.fixed-quarter {
  float: left;
  width: 25%;

  .padded & {
    padding-right: 0.35rem;
    padding-left: 0.35rem;
  }
}

// <ul>
.grid-floating-images {
  @include list-unstyled;
  @include clearfix;

  display: block;

  @include respond($break60) {
    margin-right: -1.25em;
  }

  li {
    display: block;
    width: 100%;
    margin-bottom: 1.625em;

    @include respond($break60) {
      float: left;
      width: 22.5em;
      margin-right: 1.25em;
      margin-bottom: 1.25em;
    }

    figure {
      position: relative;

      img {
        display: block;
        width: 100%;
      }
    }
  }
}

// Percentage Based Grids
// --------------------------------------------------------
@include respond($break40) {
  .col-full {
    width: 100%;
  }

  // Only used for navigation grid
  .col-sixth {
    @include columnPrototype;

    width: 16.667%;
  }

  .col-fourth {
    @include columnPrototype;

    width: 25%;
  }

  .col-fifth {
    @include columnPrototype;

    width: 20%;
  }

  .col-third {
    @include columnPrototype;

    width: 33.333%;
  }

  .col-two-fifths {
    @include columnPrototype;

    width: 40%;
  }

  .col-half {
    @include columnPrototype;

    width: 50%;
  }

  .col-three-fifths {
    @include columnPrototype;

    width: 60%;
  }

  .col-two-thirds {
    @include columnPrototype;

    width: 66.667%;
  }

  .col-three-fourths {
    @include columnPrototype;

    width: 75%;
  }

  .col-four-fifths {
    @include columnPrototype;

    width: 80%;
  }

  .offset-third {
    margin-left: 33.333%;
  }

  .col-quarter,
  .col-three-quarters {
    @include columnPrototype;

    width: 50%;
  }

  .offset-half {
    margin-left: 50%;
  }

  .offset-two-thirds {
    margin-left: 66.667%;
  }
}

@include respond($break60) {
  .col-quarter {
    width: 25%;
  }

  .col-three-quarters {
    width: 75%;
  }

  .offset-quarter {
    margin-left: 25%;
  }
}

// Grid Images
// --------------------------------------------------------
.grid-images-spaced {
  // NB: Rows use same padding as space-bottom-sub and space-bottom-line
  // respectively, but only for tablet and up.
  .row {
    @include respond($break40) {
      // 45px
      padding-bottom: 2.813em;
    }

    @include respond($break60) {
      // 60px
      padding-bottom: 3.75em;
    }

    // Lesser padding for nested rows
    .row {
      @include respond($break40) {
        // 30px
        padding-bottom: 1.875em;
      }
    }
  }

  // NB: Conversely, all grid-figures
  .grid-figure {
    margin-bottom: 1.813em;

    @include respond($break40) {
      margin-bottom: 0;
    }

    // Duplicate from .structure.scss
    @include respond($break20, max) {
      margin-right: -$containerPaddingPhone;
      margin-left: -$containerPaddingPhone;
    }
  }
}

.grid-figure-square {
  position: relative;
  margin-bottom: 1rem;

  &.padded5 {
    padding: 0.313rem;
  }

  &.padded30 {
    padding: 1.875rem;
  }

  figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;

    img {
      display: none;
    }
  }

  .figure-empty {
    height: auto; // OD
    padding: 2rem 0;
    text-align: center;
    border: 1px solid $neutral-gray50;

    @include respond($break40) {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 100%;
      padding-bottom: 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
    }

    .label {
      display: block;
      padding: 0 2rem;

      @include respond($break40) {
        position: absolute;
        top: 40%;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }
  }

  @include respond($break40) {
    margin-bottom: 1.563rem;
  }
}

// <ul class="row">
.grid-images-aligned {
  padding-top: 2.312em;

  li {
    margin-bottom: 5.625em;
  }

  figure {
    margin-bottom: 2.375em;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .figure-empty {
    padding: 2em;
    text-align: center;

    @include respond($break40) {
      position: relative;
      height: 0;
      padding: 0; // reset padding
      padding-top: 100%;
      overflow: hidden;
    }

    .label {
      @include respond($break40) {
        position: absolute;
        top: 40%;
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }

  @include respond($break40) {
    li:nth-child(3) {
      &::after {
        display: block;
        width: 100%;
        height: 0;
        clear: both;
        content: "";
      }
    }
  }
}

.grid-figure {
  position: relative;
  display: inline-block;
}

.grid-image-vertical-space {
  // 82px
  padding-bottom: 5.125em;
}

.col-quarter,
.col-third,
.col-two-fifths,
.col-half,
.col-three-fifths,
.col-three-quarters,
.col-two-thirds {
  .grid-figure {
    display: block;
    max-width: 100%;
    padding-top: 0;
    background-size: 0;

    img {
      max-width: 100%;
      height: auto;
    }

    // Hides percent sized images below tablet width,
    // TODO: Refactor this once flexible grid implementation is built
    @include respond($break40 - 1, max) {
      padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
    }

    @include respond($break40) {
      display: block;
      height: 0;
      overflow: hidden;
      background-position: 50% 50%;
      background-size: cover;

      img {
        display: none;
      }
    }

    .images-modernized & {
      img {
        width: 100%;

        @include respond($break40) {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          /* stylelint-disable-next-line */
          font-family: "object-fit: cover;"; // for object-fit-images polyfill
          object-fit: cover;
        }
      }
    }
  }

  .grid-figure.full {
    display: block;

    img {
      position: static;
      width: 100%;
      height: auto;
    }

    @include respond($break40) {
      height: auto;
      overflow: visible;

      img {
        display: block;
        height: auto;
      }
    }
  }
}

.col-full {
  .grid-figure {
    width: auto;
    max-width: 100%;
    height: auto;

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .grid-figure.full {
    display: block;

    img {
      width: 100%;
    }
  }
}
