/*! Flickity v3.0.0
https://flickity.metafizzy.co
---------------------------------------------- */
flickity-carousel {
  display: block;
  padding-bottom: 30px;

  [data-ref="carousel"] {
    block-size: calc(100vw - 44.75px);
    max-block-size: 760px;
  }

  & > nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-block-start: 1rem;

    & > button:focus-visible,
    button:focus {
      color: #b2b2b2;
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
}

.flickity-enabled {
  position: relative;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  block-size: 100%;
  // block-size: calc(100vw - 100px);
  touch-action: pan-y;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  &.focus-visible::after {
    display: block;
    content: "•";
    position: absolute;
    top: 0.125em;
    left: 0.5em;
    font-size: 2em;
    color: var(--color-secondary);
  }
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab;
  transition: height 0.25s ease-in-out;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}

.flickity-cell {
  position: absolute;
  left: 0;
  inline-size: 100%;
  min-block-size: 100%;

  & > * {
    inline-size: 100%;
  }

  img {
    position: absolute;
    inset: 0;
    block-size: 100%;
    object-fit: contain;
  }
}

// flickity-fade v1.0.0
.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
  opacity: 0 !important;
  transition: opacity 500ms linear;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
  opacity: 1 !important;
}
