// Media Query Mixins
// ------------------

// Media queries
// --------------------------------------------------------
// Respond to viewport size
// $size -> viewport size
// $operator -> 'min' or 'max'
// $aspect -> 'width' or 'height'
// It's probably easiest to set default to min for mobile first sites, and max for desktop first
// --------------------------
@mixin respond($size, $operator: min, $aspect: width) {
  @media all and (#{$operator}-#{$aspect}: $size) {
    @content;
  }
}
