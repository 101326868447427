// Project specific mixins
// -----------------------

// Content Spectrum Parameter
@mixin content-spectrum($parameter) {
  .content-spectrum.primary &,
  .content-spectrum.grand-ave & {
    #{$parameter}: $spectrum-primary;
  }

  .content-spectrum.secondary &,
  .content-spectrum.geffen-contemporary & {
    #{$parameter}: $spectrum-secondary;
  }

  .content-spectrum.tertiary &,
  .content-spectrum.pacific-design-center & {
    #{$parameter}: $spectrum-tertiary;
  }

  .content-spectrum.quaternary &,
  .content-spectrum.double-negative & {
    #{$parameter}: $spectrum-quaternary;
  }
}
