// Base mixins
// ---------

// Clearfix
// $mode accepts 'light' or 'heavy'
// 'light' is sufficient for clearing floats, while 'heavy' is required to
// prevent margin collapsing
// --------------------------
@mixin clearfix($mode: light) {
  @if $mode == heavy {
    &::before,
    &::after {
      display: table;
      content: " ";
    }

    &::after {
      clear: both;
    }
  } @else {
    &::after {
      display: block;
      clear: both;
      content: " ";
    }
  }
}

.clearfix {
  @include clearfix;
}

// Kill ye FOUT
// Depends on Typekit's webfontloader adding the .wf-active class...
// --------------------------------------------------------
@mixin kill-fout {
  visibility: hidden;

  .wf-active &, // Show when fonts become active
  .mceContentBody & { // Show if this is the RTE ;)
    visibility: visible;
  }
}

// Full bleed
// $direction accepts 'up', 'down' or 'none';
// NB using 'up' or 'down' causes the shadow to
// extend to the top or bottom of the viewport
// Only use 'none' on relatively wide elements,
// and set $width to the width of the element
// --------------------------
@mixin full-bleed($color, $direction: none, $width: 400em) {
  @if $direction == none {
    box-shadow: -$width 0 0 0 $color, $width 0 0 0 $color, (-$width * 2) 0 0 0 $color, ($width * 2) 0 0 0 $color;
  }

  @if $direction == down {
    box-shadow: 0 $width 0 $width $color;
  }

  @if $direction == up {
    box-shadow: 0 (-$width) 0 $width $color;
  }

  background-color: $color;
}

// Lists
// --------------------------------------------------------
// Floated list
// $direction accepts 'right' or 'left'
// --------------------------
@mixin floated-list($space, $direction: left) {
  @include clearfix;

  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;

  li {
    float: $direction;
  }

  li + li {
    @if $direction == left {
      margin-left: $space;
    } @else {
      margin-right: $space;
    }
  }
}

// Unstyled List
// --------------------------
@mixin list-unstyled {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

// Horizontal List
// --------------------------
@mixin list-horizontal {
  @include list-unstyled;

  li {
    display: inline-block;
  }
}

// Links, Buttons
// --------------------------------------------------------
@mixin button-unstyled {
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
}
