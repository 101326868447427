.location-metadata {
  $_border-color: $neutral-gray55;

  & + & {
    padding-top: 3.125rem;
    margin-top: 3.125rem;
    border-top: 1px solid $_border-color;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
  }

  &__section {
    flex-basis: 100%;
    padding-top: 1.25rem;
    border-top: 1px solid $_border-color;

    &:not(:last-child) {
      padding-bottom: 1.5rem;
    }

    &--half {
      position: relative;

      @include respond(500px) {
        flex-basis: 50%;
        border-top: none;

        &::before {
          position: absolute;
          top: 0;
          display: block;
          width: 95%;
          height: 1px;
          content: "";
          background-color: $_border-color;
        }

        &.location-metadata__section--left {
          padding-right: 1.25rem;

          &::before {
            left: 0;
          }
        }

        &.location-metadata__section--right {
          padding-left: 1.25rem;

          &::before {
            right: 0;
          }

          &::after {
            position: absolute;
            top: 5%;
            left: 0;
            display: block;
            width: 1px;
            height: 90%;
            content: "";
            background-color: $_border-color;
          }
        }
      }
    }
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 1.25rem;
  }

  &__block-image {
    flex-basis: 50%;
    flex-grow: 1;
    width: 225px;
    height: auto;
  }

  &__block-text {
    flex-basis: calc(50% - 20px);
    flex-grow: 1;
  }

  &__blurb {
    &:not(:first-child) {
      margin-top: 3.125rem;
      margin-bottom: 2rem;
    }
  }

  &__map {
    margin-top: 2rem;
  }

  h2 {
    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  table {
    font-size: $type50;
  }

  address {
    @include copy-secondary;

    display: block;
    font-style: normal;
  }

  img,
  iframe {
    max-width: 100%;
  }

  img {
    height: auto;
    object-fit: cover;
  }
}
