// Variables
//----------

// Colors
// --------------------------------------------------------
// Neutrals
// --------------------------
$neutral-white: #fff;
$neutral-black: #000;
$neutral-off-white: #f1f1f1; // Aside navigations
$neutral-gray10: #e5e5e5; // Location nav, location tiles
$neutral-gray20: #c2c2c2; // Disabled version of nav text
$neutral-gray30: #e5e6e7;
$neutral-gray40: #b3b3b3;
$neutral-gray50: #b2b2b2; // Footer rule, colopon, hamburger links
$neutral-gray55: #9d9f9d;
$neutral-gray60: #6e6e6e; // Nav text, footer links, figure caption category
$neutral-off-black: #404040; // Lightbox background

$linkColor: $neutral-gray60;
$linkHover: $neutral-gray50;

// Accents
// --------------------------
$accent-primary: #231f20;
$accent-error: #ea5c5c;

// Spectrum
// --------------------------
$spectrum-primary: #f04236;
$spectrum-primary-light: #f1af95;
$spectrum-primary-pale: #f4e2da;
$spectrum-secondary: #0091d2;
$spectrum-secondary-light: #9ecce4;
$spectrum-secondary-pale: #e4f6ff;
$spectrum-tertiary: #1cb55a;
$spectrum-tertiary-light: #a2d2ad;
$spectrum-tertiary-pale: #e3efe6;
$spectrum-quaternary: #231f20;
$spectrum-quaternary-light: #bfc0bf;
$spectrum-quaternary-pale: #e8eae8;
$spectrum-composite: #696d6d;

// Typographic hierarchy
// --------------------------------------------------------
// 45px
$sign30: 2.813rem;

// 41px
$sign20: 2.563rem;

// 37px
$sign10: 2.313rem;

// 34px
$type120: 2.125rem;

// 30px
$type110: 1.875rem;

// 27px
$type100: 1.688rem;

// 25px
$type90: 1.563rem;

// 23px
$type80: 1.438rem;

// $type70: ;
// 20px
$type60: 1.25rem;

// 18px
$type50: 1.125rem;

// $type40: ;
// 16px
$type30: 1rem;

// 14px
$type20: 0.875rem;

// $type10: ;

// Measurements
// --------------------------------------------------------
// $containerWidth: ∞;

$containerPaddingPhone: 1.063rem;
$containerPaddingTablet: 1.25rem;
$containerPaddingDesktop: 3.125rem;
$containerColumnPadding: 16.519%;
$containerColumnEscape: 24.667%;
$contentImageFloatPadding: 0.875rem;

// Line heights, listed in base 20
// 27.5 px
$baseLineHeight: 1.375;

// 38.46 px
$tabletLineHeight: 1.938;

// 43.766 px
$desktopLineHeight: 2.188;

@mixin verticalSpace($parameter, $unit: rem) {
  #{$parameter}: $baseLineHeight * 1 + $unit;

  @include respond($break40) {
    #{$parameter}: $tabletLineHeight * 1 + $unit;
  }

  @include respond($break60) {
    #{$parameter}: $desktopLineHeight * 1 + $unit;
  }
}

// $durationFast: 0.1s;
$duration: 0.3s;
$durationSlow: 0.4s;
$timing: ease;

// Grid Padding
$gridPadding: 0.98%;
$gridWrapper: -1%;
$gridPadding20: 1.923%;
$gridWrapper20: -2%;
$gridPadding40: 3.333%;
$gridWrapper40: -3.571%;
$gridPadding50: 4.3%;
$gridWrapper50: -4.705%;

// Font Weights
// --------------------------------------------------------
$light: 200;
$regular: 400;
$semibold: 600;
$bold: 800;
$containerMax: 1220px;

// Responsive breakpoints
// Name these whatever you like
// I chose:
// https://en.wikipedia.org/wiki/List_of_lakes_by_area
// --------------------------------------------------------
$break70: $containerMax;
$break60: 1024px;

//
$break55: 888px;
$break50: 860px;
$break40: 768px;
$break30: 500px;
$break20: 360px;
$break10: 320px;

// Z-Indeces
// --------------------------------------------------------
// Content:               0  ~   99
// Site Header:         100  ~  199
// Site Hamburger Nav:  200  ~  299
// Lightbox:            300  ~  399

$alert-bar-fallback-height: 58px;
$header-height: 58px;
