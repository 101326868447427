#dafdirectdiv {
  min-width: 260px;

  .whatThis {
    display: none;
  }

  .dafdirectInput,
  .dafdirectSelect {
    min-width: 100%;
    padding: 5px;
    font-size: 14px;
  }

  .dafdirectSelect {
    margin-bottom: 15px;
  }

  .dafdirectInputAmount {
    padding-top: 15px;
  }

  .dafdirectButtonContain {
    width: 65px;
    margin-top: 29px;
    margin-right: 0;
  }

  form img {
    width: 100%;
  }
}
