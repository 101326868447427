// Patterns
// --------

// Basic utility patterns
// --------------------------------------------------------
// Visually Hidden, for skip links and screen-reader only elements
.screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

// Image replacement, for icons and things with hidden accessibility text
.ir {
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;

  br {
    display: none;
  }
}

%thin-glyphs {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Prevents scrolling when applied to body
.no-scroll {
  overflow: hidden;

  .touchevents & {
    position: fixed;
  }
}

// Simplest show-hide class
.invisible {
  display: none;
}

.visible {
  // Important required for overrides
  display: inherit !important; /* stylelint-disable-line declaration-no-important */
}

// Responsive Utility Patterns (visually show/hide at different breakpoints)
// --------------------------------------------------------
// Hide until...
// Large phone
.visible-phablet {
  display: none;

  @include respond($break20) {
    display: inherit;
  }
}

// Hamburger transition
.visible-hamburger {
  display: none;

  @include respond($break50) {
    display: inherit;
  }
}

// Tablet
.visible-tablet {
  display: none;

  @include respond($break40) {
    display: inherit;
  }
}

// Desktop
.visible-desktop {
  display: none;

  @include respond($break60) {
    display: inherit;
  }
}

// IE9
.visible-ie9 {
  .ie9 & {
    display: inherit;
  }
}

// Show until...
// Large phone
.invisible-phablet {
  @include respond($break20) {
    display: none;
  }
}

// Tablet
.invisible-tablet {
  @include respond($break40) {
    display: none;
  }
}

// Hamburger
.invisible-hamburger {
  @include respond($break50) {
    display: none;
  }
}

// Desktop
.invisible-desktop {
  @include respond($break60) {
    display: none;
  }
}

// IE9
.invisible-ie9 {
  .ie9 & {
    display: none;
  }
}

// Sticky header
.invisible-sticky {
  @include vendor-transition(all, $duration, $timing);

  .headroom--not-top & {
    visibility: hidden;
    opacity: 0;
  }
}

// Links
// --------------------------
// Prevents user from accidentally selecting text
// on click, helpful for labels
%no-select {
  user-select: none;
}
