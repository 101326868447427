// Animations
// ----------

@include keyframes(fadeout-on-load) {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
