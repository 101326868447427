// Component Structure
// -------------------

// Structure-only classes, with parameters for margins, padding, position, display, etc.
// They may contain:
// Nested classes/elements that have structural parameters only

// NB: Containers that are modular regardless of context should use EM units
// Structure should not be dependent on typography styles but font-size CAN be used as a structural style if necessary

// Contents
// --------------------------
// - Imports
// - Prototypes
// - Utilities
// - Layout
// - Type
// - Images
// - Article Lists
// - Navigation Lists
// - Buttons/Links
// - Tables
// - Forms

// Imports
// --------------------------------------------------------
// Grid containers imported separately for your convenience
@import "grid";

// Utilities
// --------------------------------------------------------
// TODO: Stub spacing classes until more abstractions are determined
.space-bottom-half {
  padding-bottom: 0.8em;
}

.space-bottom-line {
  // 30px
  padding-bottom: 1.875em;
}

.space-bottom-subheading {
  padding-bottom: 1.5em;
}

.space-bottom-sub {
  // 30px
  padding-bottom: 1.875em;

  @include respond($break40) {
    // 45px
    padding-bottom: 2.813em;
  }

  @include respond($break60) {
    // 60px
    padding-bottom: 3.75em;
  }
}

.space-bottom-page {
  // 30px
  padding-bottom: 1.875em;

  @include respond($break40) {
    // 90px
    padding-bottom: 5.625em;
  }
}

.space-bottom-sec {
  // 60px
  padding-bottom: 3.75em;

  @include respond($break40) {
    padding-bottom: 7.5em;
  }
}

.space-top-half {
  padding-top: 0.8em;
}

.space-top-line {
  // 30px
  padding-top: 1.875em;
}

@mixin space-top-sub {
  // 30px
  padding-top: 1.875em;

  @include respond($break40) {
    // 45px
    padding-top: 2.813em;
  }

  @include respond($break60) {
    // 60px
    padding-top: 3.75em;
  }
}

.space-top-sub {
  // 30px
  padding-top: 1.875em;

  @include respond($break40) {
    // 45px
    padding-top: 2.813em;
  }

  @include respond($break60) {
    // 60px
    padding-top: 3.75em;
  }
}

.space-top-sec {
  // 60px
  padding-top: 3.75em;

  @include respond($break40) {
    padding-top: 7.5em;
  }
}

.space-top-page {
  // 30px
  padding-top: 1.875em;

  @include respond($break40) {
    // 90px
    padding-top: 5.625em;
  }
}

.space-top-mobile {
  padding-top: 1em;

  @include respond($break40) {
    padding-top: 0;
  }
}

.space-content-empty {
  height: 7.813em;

  @include respond($break40) {
    height: 10.938em;
  }
}

.space-between-sec + .space-between-sec {
  // 60px
  margin-top: 3.75em;

  @include respond($break40) {
    margin-top: 7.5em;
  }
}

// Float Wrapper (includes clearfix)
.fw {
  @include clearfix;
}

// Float Left
.fl {
  float: left;
}

.fr {
  float: right;
}

.fr-desktop {
  @include respond($break40) {
    float: right;
  }
}

// For making a quick position: relative class
.rel {
  position: relative;
}

.align-center {
  text-align: center;
}

.list-unstyled {
  @include list-unstyled;
}

// For use with show/hide elements
.show-more-detail {
  display: none;
  overflow: hidden;
}

.page-header {
  --sign-primary-margin-top: 0;
  --sign-primary-margin-bottom: 1.5em;
}

// Layout
// --------------------------------------------------------
@mixin container-prototype {
  max-width: $containerMax;
  padding: 0 $containerPaddingPhone;
  margin: 0 auto;

  @include respond($break20) {
    padding: 0 $containerPaddingTablet;
  }

  @include respond($break40) {
    padding: 0 $containerPaddingDesktop;
  }
}

.container {
  @include container-prototype;
}

// Escapes padding on narrowest container width
@mixin container-escape-phone($width: default) {
  @include respond($break20, max) {
    @if $width == auto {
      width: auto;
    }

    margin-right: -$containerPaddingPhone;
    margin-left: -$containerPaddingPhone;
  }
}

.container-escape-phone {
  @include container-escape-phone;
}

.container-escape-phone-auto {
  @include container-escape-phone(auto);
}

// Centered container column
// --------------------------
.container-col-two-thirds {
  margin: 0 auto;

  @include respond($break40) {
    padding-right: $containerColumnPadding;
    padding-left: $containerColumnPadding;
  }
}

.container-nav-dropdown {
  position: relative;
  margin-bottom: 0.813rem;

  @include respond($break40) {
    display: inline-block;
    width: 19.25em;
    margin-bottom: 0;
  }
}

.nav-dropdown-panel {
  @include vendor-transition(all, $duration, $timing);

  position: absolute;
  top: 4.125em;
  z-index: 10;
  width: 100%;
  visibility: hidden;
  opacity: 0;

  &.open {
    top: 5.625em;
    visibility: visible;
    opacity: 1;
  }
}

.article-top-right {
  @include container-escape-phone;

  position: relative;
  z-index: 0;
  margin-top: -1.563em;

  @include respond($break40) {
    margin-top: 0;
  }

  + .container-col-two-thirds .rte-content {
    position: relative;
    z-index: 10;
  }
}

.popup-bottom {
  position: relative;
  text-align: right;
  background-color: $neutral-white;

  .container-popup {
    position: absolute;
    right: 0;
    bottom: 0%;
    z-index: 0;
    opacity: 0;
    transition: bottom $duration $timing, opacity $duration $timing;

    &:hover,
    &.open {
      bottom: 100%;
      opacity: 1;
      transition: bottom $duration $timing, opacity $duration $timing;
    }
  }

  .button-calendar {
    float: left;
    padding: 1.563rem 0 0 1.313rem;
  }

  .button-popup {
    position: relative;
    z-index: 10;
    padding: 1.875rem 1.313rem 1.063rem;
  }

  .nav-social {
    padding: 1.313em 1.5em 0;
  }

  &.flush-left {
    text-align: left;

    .container-popup {
      right: auto;
      left: 0;
    }

    .button-popup {
      padding: 1.875rem 1.313rem 1.063rem 0;
    }

    .nav-social {
      padding: 1.313em 1.875em 0 0;
    }
  }

  &.left {
    text-align: left;

    .container-popup {
      right: auto;
      left: 0;
    }

    .button-popup {
      padding: 1.875rem 1.313rem 1.063rem 0;
      text-align: left;
    }

    .nav-social {
      padding: 1.313em 1.875em 0 0;
    }
  }

  .article-top-right & {
    @include respond($break40) {
      position: absolute;
      top: -1.438em;
      right: 0;
      width: 100%;
      background-color: transparent;
    }
  }

  .button-popup:hover ~ .container-popup {
    bottom: 100%;
    visibility: visible;
    opacity: 1;
  }
}

.aside-article {
  padding: 0.125em 0 1.5em;

  @include respond($break40) {
    padding: 2.375em 0;
  }

  figure {
    display: block;
    padding: 1.25em 0 1.438em;

    img {
      display: block;
      width: 100%;
    }
  }
}

.container-sticky {
  position: relative;
  width: 100%;
  height: 0;
  overflow: visible;

  &.fixed {
    @include container-prototype;

    position: fixed;
    top: 3.625rem;
    right: 0;
    left: 0;
    z-index: 50;
  }
}

// <figcaption> Used in shopify product grid
.grid-callout-primary {
  position: absolute;
  top: 1.2em;
  right: 1.2em;
  left: 1.2em;

  @include respond($break40) {
    top: 0.5em;
    right: 1em;
    left: 1em;
  }

  @include respond($break60) {
    top: 1.875em;
    right: 1.875em;
    left: 1.875em;
  }
}

.tab-content {
  @include vendor-transition(all, $duration, $timing);

  display: none;

  &.active {
    display: block;
  }
}

@mixin overlay {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 300;
  width: 100%;
  height: 100%;
  padding-bottom: 3em;
  overflow: auto;
}

.lightbox,
.lightbox-overlay {
  @include overlay;

  position: fixed;

  .container {
    position: relative;
    height: 100%;
  }

  header {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;
    padding-top: 3.75em;
    animation:
      lightbox-poc-header01 $durationSlow $timing 2s forwards,
      lightbox-poc-header02 $durationSlow $timing 6s forwards;
  }
}

.lightbox-content {
  img {
    // Important required to override Javascript
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.lightbox-close {
  position: absolute;
  top: 1.75rem;
  right: 0.875rem;
  z-index: 20;
  display: block;
  font-size: 0;

  @include respond($break40) {
    right: 1.75rem;
  }

  &::before {
    font-size: 1.875rem;
  }
}

.lightbox-previous,
.lightbox-next {
  z-index: 50;

  // NB: Display declared in appearance to override default button styles
  width: 2.5rem;
  height: 2.5rem;
  margin-top: 1.313rem;
  margin-left: 0.875rem;

  @include respond($break40) {
    position: absolute;
    top: 50%;
    margin-top: -1.313rem;
    margin-left: 0;
  }
}

.media-lightbox {
  position: absolute;
  top: 5em;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  padding: 0 0 10em;
}

.lightbox-image {
  width: 100%;
  max-width: 930px;
  height: 100%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;

  @include respond($containerMax) {
    max-width: $containerMax - 100px;
  }
}

.lightbox-video {
  position: static;
  width: 100%;
  max-width: 930px;
  margin: 0 auto;

  @include respond($containerMax) {
    max-width: $containerMax - 100px;
  }
}

.lightbox-controls {
  position: relative;
  height: 100%;
}

.lightbox-previous {
  left: 1.75rem;
}

.lightbox-next {
  @include respond($break40) {
    right: 1.75rem;
    left: auto;
  }
}

.lightbox-caption {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  padding: 0 0 1.75em;

  .container {
    max-width: 58.75rem;
    max-height: 6.875rem;
    overflow: auto;
  }
}

.article-breakdown-secondary {
  header {
    margin-bottom: 0.8em;
  }

  .heading-tertiary {
    margin: 0.938rem 0 1.125rem;
  }

  .buttons {
    margin-top: 1.125em;
  }
}

.container-tray-primary {
  @include vendor-transition(max-width, $duration, $timing);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1502;
  width: 100%;
  max-width: 0;
  overflow: hidden;

  @include respond($break50) {
    @include vendor-transition(max-height, $duration, $timing);

    bottom: auto;
    width: 100%;
    max-width: none;
    max-height: 0;
  }

  &.open {
    max-width: 15em;
    overflow: auto;

    @include respond($break50) {
      width: 100%;
      max-width: none;
      max-height: 31.25rem;
      overflow: hidden;
    }
  }

  .container {
    position: relative;

    @include respond($break50) {
      padding-top: 2.25em;
    }
  }
}

.container-header-fixed {
  @include container-prototype;

  position: absolute;
  top: 0;
  top: $alert-bar-fallback-height;
  top: var(--alert-bar-height, 0);
  right: 0;
  left: 0;

  // NB: Global z-index
  z-index: 100;
  width: 100%;

  &.headroom--not-top {
    position: fixed;
    top: 0;
    z-index: 1501;
  }

  &.sticky {
    @include respond($break60) {
      position: absolute;
    }
  }
}

.container-screen-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  // NB: Global z-index
  z-index: 100;
  width: 100%;
  padding: 0 $containerPaddingPhone;
  margin: 0 auto;
  background-color: $neutral-black;

  @include respond($break20) {
    padding: 0 $containerPaddingTablet;
  }

  @include respond($break40) {
    padding: 0 $containerPaddingDesktop;
    background-color: transparent;
  }

  &.sticky {
    @include respond($break60) {
      position: absolute;
    }
  }

  &.headroom--not-top {
    position: fixed;
  }
}

.screen-header {
  @include transition(0.5s opacity $timing, 0.5s visibility $timing);

  display: block;
  visibility: visible;
  opacity: 1;

  .overlay-open & {
    z-index: 10;
    margin-right: 20px;
    margin-left: 20px;

    .heading-tertiary {
      display: none;
    }

    .header-sticky {
      margin-top: 1em;
    }

    .header-sticky-bump {
      height: 3.625em;
    }
  }

  .open & {
    display: none;
  }

  .vjs-playing & {
    visibility: hidden;
    opacity: 0;
  }

  .vjs-active-user & {
    visibility: visible;
    opacity: 1;
  }
}

.screen-container {
  width: 100%;
  padding: 0 $containerPaddingPhone;
  margin: 0 auto;

  @include respond($break20) {
    padding: 0 $containerPaddingTablet;
  }

  @include respond($break40) {
    padding: 0 $containerPaddingDesktop;
  }
}

.screen-footer {
  .container {
    max-width: 1600px;
  }

  .screen-overlay .inner & .row {
    margin-right: 0;
    margin-left: 0;
  }

  .footer-block-phone {
    background-color: transparent;
  }
}

.screen-pagination {
  position: relative;

  span {
    display: none;
  }

  @include respond($break40) {
    span {
      display: inline;
    }
  }

  &.full-width {
    @include clearfix;

    margin: 0 -10px;

    .col {
      float: left;
      width: 25%;
      padding-right: 10px;
      padding-left: 10px;

      &.center {
        width: 50%;
      }

      a {
        display: block;
      }
    }
  }

  .previous {
    @include icon-pre($caret-left);

    @include respond($break40) {
      &::before {
        padding-right: 10px;
      }
    }
  }

  .next {
    @include icon-post($caret-right);

    @include respond($break40) {
      &::after {
        padding-left: 10px;
      }
    }
  }

  .previous::before,
  .next::after {
    vertical-align: middle;
  }
}

.header-sticky-push + .container-header-fixed {
  @include respond($break60) {
    $_alert-bar-fallback-height: 58px;

    top: calc(4.5em + #{$_alert-bar-fallback-height});
    top: calc(4.5em + var(--alert-bar-height, 0));

    &.headroom--not-top {
      top: 0;
    }
  }
}

.header-sticky {
  @include vendor-transition(margin, $duration, $timing);

  position: relative;
  margin-top: 1em;
  margin-bottom: 1.25em;

  @include respond($break60) {
    margin-top: 3.125em;

    .headroom--not-top & {
      margin-top: 1em;
    }
  }
}

.header-sticky-bump {
  height: 3.625em;

  @include respond($break60) {
    height: 12.188em;
  }
}

.header-sticky-bump-section {
  @include respond($break40) {
    height: 3.188em;
  }
}

.header-sticky-bump-pagination {
  @include respond($break60) {
    height: 9.188em;
  }
}

.nav-primary {
  position: absolute;
  top: -3px; // NP
  right: 0;
  width: 100%;

  @include respond($break60) {
    top: 0;

    .headroom--not-top & {
      top: -3px; // NP
    }
  }

  .links {
    padding-right: 5.813em;
    text-align: right;

    .shopify & {
      padding-right: 148px;
    }
  }

  .buttons {
    position: absolute;
    top: 0.375em;
    right: 0;

    button + button {
      margin-left: 1.1em;
    }

    .icon-hamburger::before {
      margin-top: 2px; // NP
    }
  }
}

.ghost-nav {
  position: relative;
  right: -1.25em;
  padding: 0 1.25em 1.25em 0;

  .nav-list {
    position: absolute;
    top: 0;
    right: 1.25em;
  }

  .overlay-open & {
    display: none;
  }
}

.popup-search {
  // !importants required to override default declarations from Google CSE
  @include vendor-transition(width, $duration, $timing);
  @include container-escape-phone;

  position: absolute;
  top: 2em;
  right: 0;
  width: 0%;
  padding-top: 0.5em;
  padding-bottom: 2.025em;
  overflow: hidden;
  background-color: $neutral-white;

  @include respond($break40) {
    @include vendor-transition(left, $durationSlow, $timing);

    top: -0.688em;
    right: 5.813em;
    left: 100%;
    width: auto;
    padding-top: 0;
    padding-bottom: 0;

    &.with-cart {
      right: 9.313em;
    }

    .shopify & {
      right: 140px;
    }
  }

  @include respond($break60) {
    top: 0;
    padding-top: 0;

    &.with-cart {
      right: 5.813em;
    }

    .headroom--not-top & {
      top: -0.688em;
    }
  }

  &.open {
    width: calc(100% + #{$containerPaddingPhone} * 2);

    @include respond($break20) {
      width: 100%;
    }

    @include respond($break40) {
      left: 10.938em;
      width: auto;
    }
  }

  .screen & {
    background-color: transparent;

    &.open {
      top: 2.3em;
      right: 5.6em;

      @include respond($break40) {
        top: 0;
      }
    }
  }

  .screen-footer &.open {
    right: 3em;
  }

  .overlay-open .nav-primary & {
    top: -1.3rem;
    right: 5.213em;
    height: 3.263rem;

    @include respond($break40) {
      top: -1rem;
      height: 3.563rem;
    }

    &.open {
      width: 50%;

      @include respond($break40) {
        width: auto;
      }
    }

    input {
      background-color: $neutral-black;

      &::placeholder {
        color: $neutral-white;
      }
    }
  }

  input {
    // !importants required to override default declarations from Google CSE
    margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */

    .headroom--not-top & {
      margin-top: -0.188rem !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.container-footer-primary {
  @include container-escape-phone;

  position: relative;

  .site-title {
    padding-right: $containerPaddingPhone;

    @include respond($break20) {
      padding-right: 0;
    }
  }

  .nav-social {
    position: static;
    padding: 1.25em 0 1.188em;
    text-align: center;
  }
}

.container-footer-secondary {
  padding-bottom: 75px;

  .form-simple {
    max-width: 21.875rem;
  }

  .fixed-two-thirds {
    position: relative;
  }

  .links {
    padding-right: 2.5rem;
  }

  .buttons {
    position: absolute;
    top: 0.375em;
    right: 0;
  }

  .popup-search {
    right: 2.5rem;

    &.open {
      left: 0;
    }
  }

  .nav-social {
    margin-top: 2.5rem;
  }
}

.nav-circle-links {
  position: relative;
  margin-bottom: 1.25em;

  @include respond($break60) {
    margin-bottom: 0;
  }

  .button-composite {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 7.813rem;
    padding: 1.625rem 0 0;

    @include respond($break70) {
      width: 9.063rem;
    }
  }
}

.media-bottom-buttons {
  position: relative;
  margin-top: 0.188em;

  .button-right {
    position: absolute;
    top: 0.688rem;
    right: 0;
  }
}

.overlay-scroll {
  @include overlay;

  z-index: 1600;
  visibility: hidden;
  opacity: 0;

  &.open {
    @include vendor-transition(all, $duration, $timing);

    visibility: visible;
    opacity: 1;
  }
}

.overlay-compatibility-error {
  position: fixed;
  inset: 0;
  z-index: 500;

  // Show with Javascript only after compatibility/cookie checks
  display: none;
  padding-top: 12.5em;
}

.container-nav-cart {
  @include container-prototype;

  position: relative;
}

.screen-overlay {
  @include transition($durationSlow opacity $timing);

  position: fixed;
  inset: 0;
  z-index: -1;
  display: none;
  opacity: 0;

  &.overlay-open {
    display: block;
    opacity: 1;
  }

  .overlay {
    position: absolute;
    inset: 0;
    background-color: $neutral-black;
    opacity: 0.7;

    @include respond($break40) {
      right: 20px;
      left: 20px;
    }
  }

  .inner {
    position: relative;
    height: 100%;
    padding: 0 20px 3.625em;
    margin-top: 3.625em;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @include respond($break40) {
      padding: 0 50px 3.625em;
    }

    .top {
      @include clearfix;

      position: relative;
      min-height: 80vh;
    }

    .bottom {
      @include clearfix;

      position: relative;
    }

    .row {
      @include clearfix;

      margin-right: -10px;
      margin-left: -10px;

      .col {
        float: left;
        width: 50%;
        padding-right: 10px;
        padding-left: 10px;

        .button-rect {
          width: 100%;
        }
      }

      + .button-rect {
        margin-top: 20px;
      }
    }

    .desktop-screen-nav {
      position: relative;
      display: none;
      margin-right: -10px;
      margin-left: -10px;

      @include respond($break40) {
        display: block;
      }

      &.full-width {
        @include clearfix;

        // margin-left: -10px;

        .col {
          float: left;
          width: 33.33%;
          padding-right: 10px;
          padding-left: 10px;

          a {
            display: block;
          }
        }
      }

      .hidden-screen-nav {
        display: none;
      }
    }

    .mobile-screen-nav {
      position: relative;
      display: block;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 56px;
      border-top: 6px solid $neutral-white;
      border-bottom: 6px solid $neutral-white;

      .button-rect {
        width: 100%;
      }

      @include respond($break40) {
        display: none;
      }
    }

    .mobile-program-nav {
      display: block;
      margin-top: 38px;

      @include respond($break40) {
        display: none;
      }

      .button-rect {
        padding: 1.15rem 0.8em;
        font-size: $type30;

        &.white {
          color: $neutral-black;
          background-color: $neutral-white;
        }
      }
    }
  }

  .hidden-screen-nav {
    visibility: hidden;
  }

  .left,
  .right {
    width: 100%;

    @include respond($break40) {
      float: left;
    }
  }

  .left {
    padding-top: 0;

    @include respond($break40) {
      width: 55%;
      padding-top: 60px;
    }

    .program-background-still {
      width: 100vw;
      height: 450px;
      margin-left: -20px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      // opacity: 0.7;

      @include respond($break40) {
        max-width: 105%;
        height: 525px;
        margin-left: 0;
        background-position: 0;
        background-size: contain;
      }
    }

    .program-details {
      display: none;
      padding-top: 50px;
      padding-right: 20px;

      .detail + .detail {
        padding-top: 10px;
      }

      @include respond($break40) {
        display: block;
      }
    }
  }

  footer {
    margin-top: 35px;

    &.container {
      padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    }

    .block-rule-top {
      border-color: $neutral-white;
    }

    .button-bare {
      color: $neutral-white;
    }

    .icon-magnify {
      color: $neutral-white;
    }

    .sitemap ul li a,
    .sitemap > li > a,
    .sitemap > li .row-secondary > a {
      color: $neutral-white;

      &:hover {
        color: $neutral-gray50;
    }
  }

    .nav-social {
      background-color: transparent;

      li {
        a {
          color: $neutral-white;

          &:hover {
            color: $neutral-gray50;
          }
        }
      }
    }

    .colophon,
    .colophon a {
      color: $neutral-white;
    }
  }

  .screen-synopsis {
    position: relative;
    margin-top: -30px;

    @include respond($break40) {
      width: 45%;
      margin-top: 190px;
    }

    header {
      position: relative;
      margin-bottom: 22px;
      text-align: center;

      @include respond($break40) {
        margin-right: 27%;
        text-align: left;
      }

      .screen-duration {
        font-size: 1.5rem;

        @include respond($break40) {
          font-size: 2.25rem;
        }
      }

      .popup-bottom {
        @include transition($duration background-color $timing);

        position: absolute;
        right: -54px;
        bottom: 10px;
        display: none;
        background-color: transparent;

        &:hover {
          background-color: $neutral-white;

          .button-popup {
            color: $neutral-black;
            background-color: $neutral-white;
          }
        }

        @include respond($break40) {
          display: block;
        }
      }

      .button-popup {
        @include transition(
          $duration background-color $timing,
          $duration color $timing
        );

        height: 23px;
        color: $neutral-white;
        background-color: transparent;

        &:hover {
          color: $neutral-black;
          background-color: $neutral-white;

          + .container-popup {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .container-popup {
        @include transition(
          $duration opacity $timing,
          $duration visibility $timing
        );

        width: 100%;
        visibility: hidden;
        opacity: 0;

        &:hover {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    hr {
      border-top: 6px solid $neutral-white;

      @include respond($break40) {
        border-top: 6px solid $neutral-white;
      }
    }

    .mobile-program-nav {
      .row {
        @include clearfix;
      }

      .popup-bottom {
        @include transition($duration background-color $timing);

        display: block;
        background-color: transparent;

        &:hover {
          background-color: $neutral-white;

          .button-popup {
            color: $neutral-black;
            background-color: $neutral-white;
          }
        }

        @include respond($break40) {
          display: none;
        }
      }

      .button-popup {
        @include transition(
          $duration background-color $timing,
          $duration color $timing
        );
        @include font-body;

        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 1.15rem 0.8em;
        font-size: $type30;
        line-height: inherit;
        color: $neutral-black;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.125em;
        background-color: $neutral-white;
        border: 2px solid $neutral-white;
        border-radius: 0;
        outline: none;

        &:hover {
          + .container-popup {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .container-popup {
        @include transition(
          $duration opacity $timing,
          $duration visibility $timing
        );

        left: auto;
        width: 208px;
        visibility: hidden;
        opacity: 0;

        @include respond($break30) {
          left: 0;
          width: auto;
        }

        &:hover {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .screen-description {
      margin-top: 30px;

      p {
        + p {
          margin-top: 1.52rem;
        }
      }

      @include respond($break40) {
        max-width: 620px;
        margin-right: 40px;
      }
    }

    .program-still-citation {
      @include font-body;

      display: none;
      margin-top: 28px;
      font-size: 0.7rem;

      @include respond($break40) {
        display: block;
      }
    }
  }

  .screen-calendar-header,
  .screen-calendar-footer {
    @include clearfix;

    .sign-quaternary {
      font-size: $type80;
      text-align: center;

      @include respond($break30) {
        font-size: 2.45rem;
      }
    }

    .screen-duration {
      @include respond($break30) {
        float: left;
      }
    }

    .screen-title {
      @include respond($break30) {
        float: right;
      }
    }

    .screen-none {
      @include respond($break30) {
        margin-top: 190px;
        margin-bottom: 190px;
      }
    }

    hr {
      margin: 20px 0;
      border-color: $neutral-white;
    }
  }

  .screen-calendar-header {
    padding-top: 38px;
  }

  .screen-grid-header {
    padding-top: 38px;

    @include respond($break40) {
      @include clearfix;

      padding-top: 78px;

      .title {
        float: left;
        width: 55%;
        margin-top: -10px;
        color: $neutral-white;
      }

      .desktop-screen-nav {
        float: left;
        width: 45%;
        color: $neutral-white;
      }
    }
  }

  .screen-calendar-grid {
    $maxSqDim: 442px;

    @include clearfix;

    padding-top: 18px;
    padding-bottom: 35px;
    margin-top: -20px;
    margin-left: -20px;

    .col-4 {
      float: left;
      width: calc(100vw - 20px);
      padding-top: 20px;
      padding-left: 20px;

      @include respond($break30) {
        width: calc((100vw - 20px) / 2);
      }

      @include respond(700px) {
        width: calc((100vw - 20px) / 3);
      }

      @include respond($break40) {
        width: calc((100vw - 60px) / 3);
      }

      @include respond($break60) {
        width: calc((100vw - 60px) / 4);
        max-width: $maxSqDim + 20px;
      }
    }

    .program-summary {
      position: relative;
      display: block;
      background-color: $neutral-white;
    }

    .program-status {
      display: none;
      height: 100%;
      color: $neutral-white;
      text-align: center;

      &.screened {
        display: block;
      }

      .status-overlay {
        position: absolute;
        inset: 0;
        background-color: $neutral-black;
        opacity: 0.7;
      }

      .status-message-wrapper {
        display: table;
        width: 100%;
        height: 100%;
      }

      .status-message {
        position: relative;
        z-index: 10;
        display: table-cell;
        vertical-align: middle;
      }

      .status-message,
      .icon-x {
        color: $neutral-white;
      }

      .icon-x::before {
        padding-top: 58px;
        font-size: 30px;
      }
    }

    .program-header-image {
      position: relative;
      width: 100%;
      height: calc(100vw - 40px);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @include respond($break30) {
        height: calc((100vw - 60px) / 2);
      }

      @include respond(700px) {
        height: calc((100vw - 80px) / 3);
        max-height: $maxSqDim;
      }

      @include respond($break40) {
        height: calc((100vw - 120px) / 3);
        max-height: $maxSqDim;
      }

      @include respond($break60) {
        height: calc((100vw - 140px) / 4);
        max-height: $maxSqDim;
      }
    }

    .program-info {
      padding: 40px;
      color: $neutral-black;

      .program-duration {
        padding-top: 22px;
      }
    }
  }

  .screen-grid {
    @include clearfix;

    padding-top: 95px;
    padding-bottom: 35px;

    @include respond($break40) {
      margin-right: -10px;
      margin-left: -10px;
    }

    @include respond($break40) {
      margin-right: -20px;
      margin-left: -20px;
    }

    .col-hidden {
      display: none;
    }

    .col {
      float: left;
      width: 100%;

      @include respond($break40) {
        padding-right: 10px;
        padding-left: 10px;
      }

      &.col-1 {
        width: 100%;
      }

      &.col-2 {
        width: 50%;
      }

      &.col-3 {
        width: 33.33%;
      }
    }

    .program-header-image {
      width: 100%;
      height: 292px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }

    .program-summary {
      display: block;

      @include respond(700px) {
        margin-right: 10px;
        margin-left: 10px;
      }

      .col & {
        margin-right: 0;
        margin-left: 0;
      }

      + .program-summary {
        margin-top: 20px;
      }
    }

    .program-info {
      width: 100%;
      padding: 50px;
      color: $neutral-black;
      background-color: $neutral-white;
    }

    .program-duration {
      padding-top: 20px;
    }

    .program-synopsis {
      padding-top: 35px;
    }
  }
}

.screen-details {
  @include transition($duration background-color $timing, 1s opacity $timing);

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 $containerPaddingPhone $containerPaddingPhone;
  background-color: $neutral-black;
  opacity: 1;

  @include respond($break30) {
    width: 55%;
    padding: 0 $containerPaddingTablet $containerPaddingTablet;
    background-color: transparent;
  }

  @include respond($break40) {
    padding: 0 $containerPaddingDesktop $containerPaddingDesktop;
  }

  .overlay-open & {
    display: none;
  }

  .vjs-playing & {
    opacity: 0;

    @include respond($break30) {
      opacity: 0;
    }
  }

  .vjs-paused & {
    opacity: 1;
  }

  .vjs-active-user & {
    @include respond($break30) {
      opacity: 1;
    }
  }

  .mobile-play {
    @include button-unstyled;

    position: relative;
    top: -18px;
    display: inline-block;
    cursor: pointer;

    @include respond($break30) {
      display: none;
    }

    .vjs-big-play-button {
      visibility: visible;
    }
  }

  .detail {
    margin-top: 6px;

    @include respond($break30) {
      margin-top: 0;

      + .detail {
        margin-top: 10px;
      }
    }
  }

  .bottom {
    @include clearfix;

    padding-top: 26px;

    @include respond($break30) {
      padding-top: 0;
      margin-left: -0.6rem;
    }

    .button-rect.minimalist {
      float: left;
      width: calc(50% - 10px);
      margin-top: 35px 0.6rem 0;

      + .minimalist {
        float: right;
      }

      @include respond($break30) {
        float: none;
        width: auto;
        margin: 20px 0.6rem 0;

        // margin: 0.6rem;

        + .minimalist {
          float: none;
        }
      }

      .desktop-only {
        display: none;

        @include respond($break30) {
          display: inline;
        }
      }

      .mobile-only {
        display: inline;

        @include respond($break30) {
          display: none;
        }
      }
    }
  }
}

// Type
// --------------------------------------------------------
.label-heading {
  margin-bottom: 0.313rem;
}

.site-title {
  text-align: right;

  footer & {
    text-align: center;
  }
}

.post-title {
  margin-bottom: 0.75rem;
}

.article-heading {
  margin-bottom: 1rem;
}

.media-title {
  padding-top: 1.25rem;
  padding-bottom: 2.313rem;

  .sign-secondary {
    padding-bottom: 0.25rem;
  }
}

.colophon {
  position: relative;
  padding: 1.375rem 0 5rem;

  a {
    display: block;
    padding-top: 0.6em;
  }

  // Uses rem units to escape font-size used
  @include respond($break40) {
    padding-top: 3.75rem;
    padding-right: 0;
    padding-left: 0;

    a {
      position: absolute;
      top: 3.75rem;
      right: 0;
      padding-top: 0;
    }
  }
}

// Images
// --------------------------------------------------------
// <figure>
.hero {
  @include container-escape-phone;

  position: relative;
  margin-bottom: 1.563em;
  overflow: hidden;

  @include respond($break40) {
    margin-bottom: 3.125em;
  }

  img {
    display: block;
    width: 100%;
  }

  &.space-sub {
    padding-bottom: 2.75em;
  }
}

.media-hero {
  @include container-escape-phone;

  text-align: center;

  img {
    display: inline-block;
    max-width: 100%;
  }

  @include respond($break40) {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 61.607%;
    overflow: hidden;
    text-align: center;

    img {
      display: none;
    }

    .images-modernized & {
      display: flex;
      justify-content: center;
      padding-top: 0;

      img {
        width: auto;
        height: 100%;
        max-height: 690px;
      }
    }
  }
}

.media-hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;

  .hover-dialog {
    @include vendor-transition(opacity, $duration, $timing);

    position: absolute;
    top: 30%;
    left: 50%;
    width: 7.813em;
    margin-left: -3.907em;
  }
}

.media-hero-empty {
  display: table;
  width: 12.5em;
  height: 12.5em;
  margin: 0 auto;
  text-align: center;

  @include respond($break40) {
    width: 18.75em;
    height: 18.75em;
  }

  span {
    display: table-cell;
    padding: 0 1.3rem;
    vertical-align: middle;
  }
}

// <figcaption>
.caption-bottom {
  padding: 0.6rem 0.75rem;

  @include respond($break20) {
    padding: 0.937rem 0 0;
  }
}

// <figcaption>
.caption-bottom-overlay {
  @include vendor-transition(transform, $duration, $timing);
  @include translateY(100%);

  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;

  // Assign hover events to non-touch devices only
  @media (hover: hover) {
    :hover > & {
      @include translateY(0%);
    }
  }

  @media (hover: none) {
    &.open {
      @include translateY(0%);
    }
  }
}

.caption-corner {
  @include respond($break40) {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 75%;
    margin-top: 0;
  }
}

.caption-corner-detail {
  display: none;
  text-align: center;
  opacity: 0;
}

// Can be a <figcaption> or div
.caption-top-right {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem 1.125rem;
}

// <figure>
.content-image {
  position: relative;
  margin-right: -$containerPaddingPhone;
  margin-left: -$containerPaddingPhone;
  font-size: $type60;

  @include respond($break40) {
    margin-right: 0;
    margin-left: 0;
  }

  img {
    display: block;
    max-width: 100%;
  }

  p + & {
    @include verticalSpace(margin-top);
  }
}

.content-image + p,
.content-image + .content-image {
  margin-top: 1em;

  @include respond($break40) {
    margin-top: 2em;
  }

  @include respond($break60) {
    margin-top: 3em;
  }
}

.screen .vjs-big-play-button {
  position: absolute;
  inset: 0;
  display: block !important; /* stylelint-disable-line declaration-no-important */
  width: 100%;
  height: 100%;
  padding: 0;
  visibility: hidden;
  background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
  border: 0 !important; /* stylelint-disable-line declaration-no-important */

  &::before,
  span {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.screen-video {
  position: absolute;
  inset: 0;

  .overlay-open & {
    display: none;
  }

  .vjs-poster {
    display: none;
  }

  video,
  .vimeoFrame,
  iframe {
    @include transition(
      $durationSlow opacity $timing,
      $durationSlow visibility $timing
    );

    visibility: hidden;
    opacity: 0;
  }

  .video-js {
    @include transition($durationSlow background-color $timing);

    &.vjs-fluid {
      height: 100vh;
      padding-top: 0;
    }

    &.vjs-paused {
      background-color: transparent;

      video,
      .vimeoFrame,
      iframe {
        visibility: hidden;
        opacity: 0;
      }
    }

    &.vjs-playing {
      background-color: $neutral-black;

      video,
      .vimeoFrame,
      iframe {
        visibility: visible;
        opacity: 1;
      }
    }

    &.vjs-ended {
      background-color: transparent;

      video,
      .vimeoFrame,
      iframe {
        visibility: hidden;
        opacity: 0;
      }
    }

    .vimeoFrame {
      height: 100vh !important; /* stylelint-disable-line declaration-no-important */
    }

    .vjs-control-bar {
      z-index: 10;
      display: none;
      width: 100%;
      margin-bottom: 59px;
      margin-left: 60%;
      background-color: transparent;

      @include respond($break20) {
        width: calc(40% - 1.25rem);
      }

      @include respond($break30) {
        display: block;
      }

      @include respond($break40) {
        width: calc(40% - 3.125rem);
      }
    }

    .vjs-current-time,
    .vjs-duration,
    .vjs-time-divider {
      display: inline-block;
      float: right;
    }

    .vjs-remaining-time-display {
      display: none;
    }

    .vjs-play-control {
      float: left;
      width: 18px;
      margin-left: -2px;
    }

    .vjs-volume-menu-button,
    .vjs-fullscreen-control {
      float: right;
      width: 25px;

      &:hover .vjs-menu {
        display: none;
      }
    }

    .vjs-progress-control {
      width: 100%;
      height: 15px;

      .vjs-slider {
        background-color: $neutral-white;
      }

      .vjs-load-progress,
      .vjs-load-progress div {
        background-color: transparent;
      }

      .vjs-play-progress::before {
        width: 1em;
        height: 1em;
        content: "";
        background-color: $neutral-white;
      }

      // .custom-progress, .custom-remaining {
      //   position: absolute;
      //   display: block;
      //   height: 0.3em;
      //   margin: 0;
      //   padding: 0;
      //   top: 0;
      //   background-color: $neutral-white;
      // }

      // .custom-progress {
      //   left: 0;
      //   width: 0;
      // }

      // .custom-remaining {
      //   right: 0;
      //   width:  calc(100% - 1em);
      // }
    }

    &.vjs-no-flex .vjs-progress-control {
      display: block;
      float: left;
    }
  }
}

.screen-stills {
  position: fixed;
  inset: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  .still {
    @include transition($duration opacity $timing);

    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }
}

// Article Lists
// --------------------------------------------------------
// <ul>
.list-titles-padded {
  @include list-unstyled;

  padding: 1.6em 0 2.5em;

  @include respond($break40) {
    padding: 3.813em 0;
  }

  &.columnar {
    @include respond($break40) {
      column-count: 3;
      column-gap: 24px;
    }
  }

  li {
    h4::after {
      display: block;
      height: 1.4em;
      content: "";
    }

    .list-heading {
      page-break-inside: avoid;
      break-inside: avoid;

      h4 {
        line-height: 1.4;
      }

      .listing:last-child::after {
        display: block;
        height: 1.4em;
        content: "";
      }
    }

    .listing {
      page-break-inside: avoid;
      break-inside: avoid;

      &::after {
        display: block;
        height: 1.4em;
        content: "";
      }

      &:last-child::after {
        display: block;
        height: 3em;
        content: "";
      }
    }
  }
}

// <ul>
// Used for events lists
.list-article-vertical {
  @include list-unstyled;
  @include container-escape-phone;

  margin-bottom: 2.063rem;

  @include respond($break40) {
    margin-bottom: 4.688rem;
  }

  li + li {
    margin-top: 1em;

    @include respond($break40) {
      margin-top: 4.563em;
    }
  }

  .container-min-height {
    position: relative;
    min-height: 10.75rem;

    @include respond($break60) {
      min-height: 17.188rem;
    }
  }

  .left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 10.938em;
    padding: 1.5em;

    .label {
      .indent {
        margin-left: 1em;
      }
    }
  }

  .middle {
    width: 100%;
    padding: 0;

    @include respond($break40) {
      padding-right: 36%;
    }

    @include respond($break60) {
      padding-left: 10.938em;
    }
  }

  .container-article {
    padding: 1.188rem 0.938rem 1.063rem;

    @include respond($break40) {
      padding: 1.375rem 1.75rem 1.75rem 1.188rem;
    }

    @include respond($break60) {
      padding: 1.375rem 3.125rem 1.75rem 2.625rem;
    }

    .heading-secondary {
      margin-bottom: 0.92em;
    }

    .copy-secondary {
      margin-bottom: 1.063em;
    }
  }

  figure {
    position: static;
    width: 100%;

    img {
      display: block;
      width: 100%;
      height: auto;

      @include respond($break40) {
        display: none;
      }
    }

    @include respond($break40) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 36%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
  }

  .event-callout {
    .container {
      position: relative;
      padding: 0;
      margin: 0;
    }

    .label-block {
      color: $neutral-white;
      background-color: $accent-primary;

      .label {
        color: $neutral-white;

        &.gray {
          color: $neutral-gray10;
        }
      }
    }

    .left {
      width: 11.738em;
    }

    .middle {
      @include respond($break40) {
        padding-right: 0;
      }

      @include respond($break60) {
        padding-left: 10.938em;
      }

      .copy-secondary,
      .heading-secondary {
        margin-bottom: 0.2em;
      }
    }
  }
}

.list-article-vertical-small {
  @include list-unstyled;

  display: block;
  width: 100%;

  @include respond($break40) {
    max-width: 28.125em;
    margin-top: 3.375em;
  }

  li + li {
    margin-top: 0.875em;

    @include respond($break40) {
      margin-top: 1.25em;
    }
  }

  li {
    display: block;
    width: 100%;

    .container-label {
      padding-bottom: 1.188em;
    }

    .heading-primary {
      padding-right: 7.5rem;
    }

    .circle-link {
      margin-top: 1.25rem;
    }
  }
}

.list-article-details {
  @include list-unstyled;

  li + li {
    margin-top: 2.813em;
  }

  .label {
    padding-bottom: 0.813rem;
  }
}

.list-article-ruled {
  @include list-unstyled;

  padding-bottom: 60px;

  > li {
    min-height: 220px;
    padding-top: 30px;
    margin-bottom: 18px;

    &:first-child {
      padding-top: 0;
    }

    @include respond($break40, min) {
      padding-top: 25px;
      margin-bottom: 0;
    }
  }

  article {
    padding-bottom: 25px;

    @include respond($break40, min) {
      > * {
        padding-top: 25px;
      }
    }

    .col-fourth {
      padding-left: 0 !important; /* stylelint-disable-line declaration-no-important */
    }

    .heading-secondary {
      margin-bottom: 0.75rem;
    }
  }

  figure {
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include respond($break40, min) {
      padding-top: 1.563rem;
      padding-bottom: 1.563rem;
    }

    &.col-fifth {
      padding-right: 3rem;
      padding-left: 3rem;

      @include respond($break40, min) {
        padding-right: 0.98%;
        padding-left: 0.98%;
      }
    }

    img {
      width: 100%;
    }

    &.figure-empty {
      text-align: center;

      div {
        span {
          position: relative;
          top: 85px;
          margin: 0 auto;
        }

        position: relative;
        width: 100%;
        min-height: 200px;
        padding: 0 0.2rem;
        color: $neutral-gray60;
    }
  }
}
}

.list-text-two-column {
  @include list-unstyled;

  // Overwrite default rte list
  padding-left: 0 !important; /* stylelint-disable-line declaration-no-important */

  .list-heading {
    display: block;
    width: 100%;
  }

  .list {
    display: block;

    @include respond($break40) {
      column-count: 2;
      column-gap: 14px;
    }

    ul {
      @include list-unstyled;
    }
  }
}

// Navigation Lists
// --------------------------------------------------------

// <ul>
.list-circle-links {
  @include list-horizontal;
  @include clearfix;

  @include respond($break40) {
    margin-right: -0.281rem;
    margin-left: -0.281rem;
  }

  @include respond($break60) {
    padding: 0 9em 0 1.75em;
    margin: 0;
  }

  @include respond($break70) {
    padding-right: 10.938em;
  }

  li {
    display: block;
    margin-bottom: 0.188rem;

    @include respond($break40) {
      float: left;
      width: 50%;
      padding-right: 0.281rem;
      padding-left: 0.281rem;
      margin-bottom: 0.25rem;
    }

    @include respond($break60) {
      display: inline-block;
      float: none; // OD
      width: auto;
      padding: 0;
      margin-bottom: 0;
    }
  }

  li + li {
    @include respond($break60) {
      margin-left: 1.25em;
    }
  }
}

//<ul>
.list-horizontal {
  @include list-horizontal;
}

// <ul>
.list-horizontal-labels,
.list-shop-breadcrumbs {
  @include list-horizontal;

  li {
    margin-right: 2.125em;
  }

  li:last-child {
    margin-right: 0;
  }

  .header-sticky & {
    position: relative;
    z-index: 20;
    margin-top: 1.813em;
  }

  &.caret {
    li {
      margin-right: 1.1em;
    }

    li:last-child {
      margin-right: 0;
    }
  }
}

.list-shop-breadcrumbs {
  margin-top: 20px;
}

// <nav>
.container-list-dropdown {
  position: relative;
  z-index: 0;
}

.list-dropdown-trigger {
  @include button-unstyled;

  position: absolute;
  top: 0;
  width: 100%;
  height: 3.875em;
}

// <ul>
.range {
  @include list-unstyled;
  @include clearfix;

  // Min height to prevent height transitions on tab switch
  min-height: 6.25em;
  margin: 2em 0 0.5em;

  @include respond($break40) {
    min-height: 8.75em;
    margin: 2em -1.563em 2.75em;
  }

  li {
    float: left;
    padding: 0;
    margin: 0;

    a {
      display: block;
      width: 100%;
    }
  }
}

.range-fifth {
  width: 20%;
}

.range-sixth {
  width: 16.5%;
}

// <ul>
.sitemap {
  @include list-unstyled;
  @include clearfix;

  display: none;

  @include respond($break50) {
    display: block;

    .container-tray-primary & {
      margin-bottom: 2.875em;
    }

    .container-footer-primary & {
      padding-bottom: 3.313em;
    }
  }

  > li {
    position: relative;
    display: block;
    min-height: 18.938em; // To allow for absolutely positioned secondary rows
    > a {
      display: block;
    }
  }

  ul {
    @include list-unstyled;

    margin-bottom: 1.875em;

    li + li {
      margin-top: 0.375em;
    }
  }

  .row-secondary {
    position: absolute;
    top: 9.6em;

    @include respond($break60) {
      top: 8em;
    }
  }
}

// <ul>
.sitemap-vertical {
  @include list-unstyled;

  padding: 0.688rem 0;

  > li {
    > a {
      display: block;
      padding: 0 1.688rem 0 2rem;
    }
  }

  > li + li {
    margin-top: 1.125em;
  }

  ul {
    @include list-unstyled;

    display: none;
    margin-top: 0.25rem;
    overflow: hidden;

    li {
      a {
        position: relative;
        display: block;
        padding: 0 1.688rem 0 2rem;
      }
    }
  }
}

// Buttons
// --------------------------------------------------------
.screen-overlay-close {
  @include icon-pre($x);

  position: absolute;
  top: 8px;
  right: 85px;
  display: none;
  color: $neutral-white;
  cursor: pointer;

  .overlay-open & {
    display: inline-block;
  }
}

.button-double {
  @include clearfix;

  .button-rect {
    float: left;
    width: 49%;
    padding-right: 0;
    padding-left: 0;
  }

  .button-rect + .button-rect {
    margin-top: 13px;
    margin-left: 0;

    @include respond($break55) {
      margin-top: 0;
      margin-left: 1.75%;
    }
  }
}

.container-cart-status {
  margin-left: 13px;

  @include respond($break60) {
    position: relative;
    top: -2.5px;
  }

  .icon-cart {
    margin-right: 0.125rem;

    @include respond($break60) {
      margin: 0 0.313rem;
    }
  }
}

// <a>
.content-image-button {
  display: block;
  width: 100%;
  padding: 2.75rem 0 2.375rem;
  margin: 2.625rem 0;
  text-align: center;
  background-size: cover;
}

// <a>
.image-button-tab-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 25%;
  max-width: 16.875em;

  .container-button {
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: -1.719rem;
    text-align: center;
  }
}

.tray-close {
  position: absolute;
  top: 1.125em;
  right: 1.687rem;

  // Local z-index (base 200)
  z-index: 10;

  @include respond($break50) {
    position: absolute;

    .open & {
      position: absolute;
    }
  }
}

.overlay-close {
  position: absolute;
  top: 1.125rem;
  right: 0;
  z-index: 10;
}

// Tables
// --------------------------------------------------------
.table-copy {
  margin-bottom: 0.563em;

  tbody {
    tr {
      td {
        padding-right: 0.625rem;
      }
    }
  }
}

// Id scoping required to override theme defaults
/* stylelint-disable selector-max-id  */
#cart .cart-minimal {
  display: block;
  margin-bottom: 2em;

  @include respond($break40) {
    display: table;
    margin-bottom: 0;
  }

  thead {
    display: none;

    @include respond($break40) {
      display: table-row-group;
    }

    tr {
      th {
        text-align: center;
      }

      th:first-child {
        text-align: left;
      }
    }
  }

  tbody {
    display: block;

    @include respond($break40) {
      display: table-row-group;
    }

    tr {
      position: relative;
      display: block;
      width: 100%;

      @include respond($break40) {
        display: table-row;
        padding: 0;
      }

      td {
        min-width: 100%;
        padding: 0.3rem 1.875rem 0.3rem 0;
        text-align: left;

        @include respond($break40) {
          display: table-cell;
          min-width: 0;
          padding: 0.875rem;
          text-align: center;
        }
      }

      td.image {
        display: block;
        max-width: 115px;
        padding-left: 0;
        text-align: left;

        .label-mobile {
          @include font-body;

          display: block;
          padding: 20px 0 10px;

          .variant_title {
            display: inline;
            color: $neutral-black;
          }

          @include respond($break40) {
            display: none;
          }
        }

        .product_image {
          max-width: 7.188rem;
        }

        .product_image.empty {
          a {
            display: block;
            width: 7.188rem;
            height: 7.188rem;
            padding-top: 1.5rem;
            text-align: center;
            border: 1px solid $neutral-gray50;
          }
        }

        @include respond($break40) {
          display: table-cell;

          a img {
            display: block;
          }
        }
      }

      td.item {
        padding-top: 1.3rem;
      }

      td.qty {
        padding: 12px 0 31px;

        .heading-cart {
          padding: 0 0 10px;
        }

        @include respond($break40) {
          padding: 0.3rem 1.875rem;
        }
      }

      td.price {
        position: absolute;
        bottom: 0;
        padding: 0 0 40px;
        text-align: right;

        .heading-cart {
          padding: 0 0 16px;
          margin-right: -10px;
        }

        @include respond($break40) {
          position: relative;
          padding: 0.3rem 1.875rem;
          text-align: center;
        }
      }

      td.remove {
        position: absolute;
        top: 1.3rem;
        right: 0;
        min-width: 0;
        padding: 0;
        text-align: right;

        .icon-x {
          display: inline-block;
          vertical-align: middle;
        }

        @include respond($break40) {
          position: relative;
          top: 0;

          .icon-x {
            margin-top: -3px; //NP
            margin-right: 0.5em;
          }
        }
      }
    }

    tr.summary {
      td.image {
        display: none;

        @include respond($break40) {
          display: table-cell;
        }
      }

      td.summary-price {
        display: block;
        padding: 0;
        text-align: right;

        .heading-cart {
          padding: 26px 0 7px;
          margin-right: -10px;
        }

        @include respond($break40) {
          display: table-cell;
          padding: 0.3rem 1.875rem;
          text-align: center;
        }
      }

      td.price {
        display: block;
        border-top: 1px solid $neutral-gray50;

        @include respond($break40) {
          display: table-cell;
        }
      }
    }
  }
}
/* stylelint-enable selector-max-id  */

// Forms
// --------------------------------------------------------
.flex-selects-right {
  @include respond($break60) {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;

    .ie9 & {
      display: block;
    }
  }

  .flex-selects {
    display: none;
    width: 100%;
    overflow: hidden;

    @include respond($break60) {
      display: flex;
      display: inherit;
      flex-wrap: nowrap;
      width: auto;
      overflow: visible;

      .ie9 & {
        display: none;
        width: 100%;
        overflow: hidden;
      }
    }
  }

  .flex-left-full {
    padding-right: 5.625rem;
    line-height: 2.125rem;

    .button-caret-circle-right {
      margin-left: 0.406rem;
    }

    @include respond($break60) {
      flex-grow: 1;
      padding-right: 0;

      .block-neutral10 {
        padding: 1.438rem 2.25rem;
      }

      .ie9 & {
        padding-right: 5.625rem;
      }
    }
  }

  .flex-right {
    width: 100%;
    margin-top: 1.25rem;

    @include respond($break60) {
      flex-grow: 1;
      max-width: 13.75rem;
      margin-top: 0;
      margin-left: 1.25rem;

      select {
        max-height: 5.125rem;
        padding-top: 1.938rem;
        padding-bottom: 1.938rem;

        // Reqiured to match wits with all browsers
        line-height: 1.375em;
      }

      .ie9 & {
        max-width: none;
        margin-top: 1.25rem;
        margin-left: 0;

        select {
          padding-top: 1.5em;
          padding-bottom: 1.5em;
        }
      }
    }
  }
}

.flex-selects-header {
  @include respond($break40) {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;

    .ie9 & {
      display: block;
    }
  }

  .flex-selects {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    width: auto;
    overflow: visible;

    @include respond($break40) {
      margin-right: -1%;
    }

    .ie9 & {
      display: none;
      width: 100%;
      overflow: hidden;
    }
  }

  .flex-left-full {
    padding: 1.438rem 1.25rem;
    background-color: none;

    @include respond($break40) {
      flex-grow: 1;
      padding: 1.438rem 2.25rem;
      padding-right: 0;
      line-height: 2.125rem;
      background-color: #e5e5e5;

      .ie9 & {
        padding-right: 5.625rem;
      }
    }
  }

  .flex-right {
    width: 100%;

    @include respond($break40) {
      margin-top: 0;
      margin-left: 1.25rem;

      // -ms-flex: 1 1 auto;
      select {
        max-height: 5.125rem;
        padding-top: 1.938rem;
        padding-bottom: 1.938rem;

        // Reqiured to match wits with all browsers
        line-height: 1.375em;
      }

      .ie9 & {
        max-width: none;
        margin-top: 1.25rem;
        margin-left: 0;

        select {
          padding-top: 1.5em;
          padding-bottom: 1.5em;
        }
      }
    }
  }
}

// Single button <forms> used for external "Add to Cart" type buttons
.form-button-full {
  & + & {
    margin-top: 0.35rem;
  }
}

.form-button-break {
  float: left;
  margin-top: 1.875rem;
  margin-right: 0.625rem;

  @include respond($break40) {
    // 45px
    margin-top: 2.813rem;
  }

  @include respond($break60) {
    // 60px
    margin-top: 3.75rem;
  }
}

.form-button-inline {
  display: inline-block;

  & + & {
    margin-left: 0.188rem;
  }
}
