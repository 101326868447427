// Content
// -------

// Content scoped modifications to existing component classes. Currently there are two types of content scopes:
// - Content rendered by templates/October components (Component content)
// - Content rendered by the RTE (RTE content)

// Component Content/RTE Content
// --------------------------------------------------------
.component-content,
.rte-content {
  // Images
  // --------------------------
  %content-image-prototype {
    max-width: 100%;
    height: auto;
  }

  // Default image behavior
  img {
    @extend %content-image-prototype;
  }

  .content-image-center {
    text-align: center;

    .container-col-two-thirds & {
      @include respond($break40) {
        margin-right: -$containerColumnEscape;
        margin-left: -$containerColumnEscape;
      }
    }

    img {
      @extend %content-image-prototype;
    }
  }

  .content-image-right {
    text-align: right;

    img {
      @extend %content-image-prototype;
    }
  }

  .content-image-left {
    text-align: left;

    img {
      @extend %content-image-prototype;
    }
  }

  .content-image-float-right {
    text-align: right;

    @include respond($break40) {
      float: right;
      margin-bottom: 1em;
      margin-left: $contentImageFloatPadding;

      .container-col-two-thirds & {
        margin-right: -$containerColumnEscape;
      }
    }

    img {
      @extend %content-image-prototype;
    }
  }

  .content-image-float-left {
    @include respond($break40) {
      float: left;
      margin-right: $contentImageFloatPadding;
      margin-bottom: 1em;

      .container-col-two-thirds & {
        margin-left: -$containerColumnEscape;
      }
    }

    img {
      @extend %content-image-prototype;
    }
  }

  hr {
    margin-top: 0.938em;
    margin-bottom: 1.5em;

    @include respond($break40) {
      border-top: 2px solid $neutral-gray50; // OD
    }
  }

  // Video
  // --------------------------
  .content-video-embed {
    @include verticalSpace(margin-bottom);

    .container-col-two-thirds & {
      @include respond($break40) {
        margin-right: -$containerColumnEscape;
        margin-left: -$containerColumnEscape;
      }
    }
  }
}

// Component Content only
// --------------------------------------------------------
// Hiding this for now in favor of padded-v
//.component-content {
//  .row + .row {
//    @include respond($break40) {
//      @include verticalSpace(margin-top);
//    }
//  }
//}

// Special Content Block Types
// --------------------------------------------------------
section.content-block {
  @include container-escape-phone;

  > h2,
  .rte-content {
    @include respond($break20, max) {
      padding-right: $containerPaddingPhone;
      padding-left: $containerPaddingPhone;
    }
  }

  > figure img {
    max-width: 100%;
  }
}

.col-two-thirds + .content-block {
  margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.content-block + .col-two-thirds {
  margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.content-block {
  + .content-block {
    margin-top: 3.1em;

    @include respond($break40) {
      margin-top: 3.1em;
    }
  }

  + .content-block.space-between-sec {
    margin-top: 7.5em;

    @include respond($break40) {
      margin-top: 7.5em;
    }
  }
}

section.content-subsection {
  h2.heading-quinary {
    @include font-head;

    font-size: $type100;
  }

  .rte-content {
    h3 {
      @include font-head;

      font-size: $type90;
    }

    h4 {
      @include font-head;

      font-size: $type60;
    }
  }
}

section.content-lead {
  h2.heading-quinary {
    @include heading-quinary;

    font-size: $sign10;
  }

  .rte-content {
    h3 {
      @include heading-quinary;

      font-size: $type120;
    }

    h4 {
      @include heading-secondary;

      font-size: $type100;
    }

    p {
      @include copy-lead;
    }

    p.copy-emphasized {
      @include copy-lead-emphasized;
    }
  }
}

// RTE Content only
// --------------------------------------------------------
.rte-content {
  // Copy
  // --------------------------
  p {
    @include copy-secondary;
  }

  p.lead {
    @include copy-lead;
  }

  p.copy-emphasized {
    @include copy-emphasized;
  }

  ul {
    @include list-dotted;

    padding-bottom: 0.813em;
  }

  ol {
    @include numbers-circled;

    padding-bottom: 0.813em;

    li {
      @include copy-secondary;
    }

    li + li {
      margin-top: 1.875rem;
    }
  }

  iframe {
    max-width: 90vw;
    max-height: 50vw;
  }

  p + ul,
  p + ol,
  ul + ul,
  ul + ol,
  ol + ul,
  ol + ol {
    padding-top: 1.375em;

    @include respond($break40) {
      padding-top: 1.875em;
    }
  }

  ul + p,
  ol + p {
    padding-top: 0.5em;
  }

  blockquote {
    @include blockquote;
  }

  p + .content-video-embed,
  ul + .content-video-embed,
  ol + .content-video-embed {
    @include verticalSpace(margin-top);
  }

  // Links
  // --------------------------
  p.button-arrow-down a,
  a.button-arrow-down {
    @include icon-post($arrow-down);

    display: inline-block;
    margin-left: 1.327em;
    font-size: 0.706em;
    color: $accent-primary;
    vertical-align: middle;
  }

  .hidden-content {
    // display: none;
  }

  // Headings
  // --------------------------
  h3 {
    @include heading-quinary;

    margin-bottom: 1.2em;
  }

  h4 {
    @include heading-secondary;
  }

  .copy-emphasized-underline {
    @include copy-emphasized-underline;

    margin-bottom: 1.75rem;
  }

  h4 + p,
  h4 + ul {
    margin-top: 0.938rem;
  }

  h4 + ol {
    margin-top: 1.2rem;
  }

  p + h3,
  .content-image + h3,
  ul + h3,
  ol + h3 {
    margin-top: 2.55rem;
  }

  p + h4,
  .content-image + h4,
  ul + h4,
  ol + h4,
  ul + .heading-copy,
  ol + .heading-copy {
    margin-top: 1.609em;
  }

  .label + h4,
  .label + .heading-copy {
    margin-top: 0.26em;
  }

  h3 + h4 {
    // Uses top padding to avoid margin compounding
    padding-top: 0.522em;
  }
}

// Read More
// --------------------------
.read-more-group {
  height: 0;
  overflow: hidden;

  &.open {
    margin-top: 1.375rem;

    // Allow explicit media queries for mobile first sizing
    @media all and (min-width: 768px) {
      margin-top: 1.938rem;
    }

    @media all and (min-width: 1024px) {
      margin-top: 2.188rem;
    }
  }
}

.read-more-toggle {
  position: relative;
  visibility: hidden;

  @include transition(0.4s visibility ease);

  .read-more-overlay {
    position: absolute;
    bottom: 100%;
    display: none;
    width: 100%;
    height: 85px;
    background: transparent;
    box-shadow: inset -5px -5px 10px $neutral-white;
  }

  a {
    @include icon-post($caret-down);

    display: none;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $accent-primary;
    text-decoration: none;

    &::after {
      padding-left: 8px;
      font-size: 11px;
      vertical-align: middle;
    }
  }

  &.active {
    visibility: visible;

    @include transition(0.4s visibility ease);
    @include transition-delay(0.4s);

    .read-more-overlay {
      display: block;
    }

    a {
      display: block;
    }
  }
}

.hide-more-toggle {
  @include icon-post($caret-up);

  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $accent-primary;
  text-decoration: none;

  &::after {
    padding-left: 8px;
    font-size: 11px;
    vertical-align: middle;
  }
}

.cta-container {
  display: flex;
  justify-content: space-between;

  .join-button {
    height: 47px;
    padding: 15px 10px;
    margin-top: 15px;

    @include respond($break30) {
      padding: 15px 25px;
    }
  }
}
