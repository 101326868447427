// Component Typography and Prototypes
// -----------------------------------

// Template typography classes and mixins/prototypes that can be extended into content classes.
// Typographic classes can contain some structural (not textural) design where it does not require abstraction,
// and textural classes dealing with typography (color, text-decoration, pseudo elements that modify text, etc).

// They may contain:
// Nested classes or elements with typographic/basic structural modifications only, for instance a header
// with <span> classes inside. These should not

// Contents
// --------------------------
// - Utilities
// - Prototypes
// - Copy
// - Headings
// - Subheadings
// - Signage
// - Labels
// - Lists
// - Forms

// Prototypes
// --------------------------------------------------------
@mixin label-prototype {
  @include font-body;

  font-size: $type20;
  text-transform: uppercase;
  letter-spacing: 0.125em;

  &.gray,
  .gray {
    color: $neutral-gray60;
  }

  &.emphasized,
  .emphasized {
    color: $accent-error;
  }
}


// Utilities
// --------------------------------------------------------
.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}


// Copy
// --------------------------------------------------------
// NB: @mixin copy-primary is declared in base partial for proper css ordering
@mixin copy-secondary {
  @include font-body(light);

  font-size: $type50;
  line-height: 1.556;
}

.copy-primary {
  @include copy-primary;
}

@mixin copy-lead {
  @include copy-primary;
  @include verticalSpace(margin-bottom);

  @include respond($break40) {
    @include font-body(light);

    margin-bottom: 1.778em;
    font-size: $type100;
    line-height: 1.6;
  }
}

@mixin copy-lead-emphasized {
  @include copy-lead;

  font-style: oblique;
}

.lead {
  @include copy-lead;
}

.copy-secondary {
  @include copy-secondary;

  // Also apply to nested <p> tags for use with template content
  p {
    @include copy-secondary;
  }
}

// Used only in RTE
@mixin copy-emphasized {
  @include copy-secondary;

  font-size: $type60;
  font-weight: $regular;
  line-height: 1.2; // OD
}

@mixin copy-small {
  @include font-body(light);

  font-size: $type30;
  line-height: 1.416;
}

@mixin copy-screen {
  @include font-body(light);

  font-size: $type50;
  line-height: 1.7;
}

@mixin copy-emphasized-underline {
  @include copy-emphasized;

  padding-bottom: 0.65em;
  border-bottom: 1px solid $neutral-gray50;
}

.copy-small {
  @include copy-small;
}

.copy-small-inherit {
  p {
    @include copy-small;
  }
}

.copy-screen {
  @include copy-screen;
}

// Can be abstracted if this changes
.colophon {
  font-size: 0.812em;
  color: $neutral-gray60;

  a {
    color: $neutral-gray60;
  }
}

// Headings
// --------------------------------------------------------
@mixin heading-primary {
  @include font-head;

  font-size: $type80;
  line-height: 1.304;
}

@mixin heading-secondary {
  @include font-head;

  font-size: $type90;
}

@mixin heading-tertiary {
  @include font-head;

  font-size: $type60;
  line-height: 1.501;

  &.gray,
  .gray {
    color: $neutral-gray60;
  }
}

@mixin heading-quaternary {
  @include font-head;

  font-size: $type100;
  line-height: 1.296;
}

@mixin heading-quinary {
  @include font-head;

  font-size: $type120;
}

@mixin heading-copy {
  @include font-body;

  font-size: $type80;
}

.heading-primary {
  @include heading-primary;
}

.heading-secondary {
  @include heading-primary;

  @include respond($break40) {
    @include heading-secondary;
  }
}

.heading-tertiary {
  @include heading-tertiary;
}

.heading-quinary {
  @include heading-quinary;

  margin-bottom: 1.2em;
}

.heading-quinary-underlined {
  @include heading-quinary;

  text-decoration: underline;
}

.heading-index {
  @include heading-copy;

  margin-bottom: 0.607em;
  font-size: 1.75rem;
}

.heading-label {
  @include label-prototype;

  margin-bottom: 0.8em;
  font-size: $type60;
}

.heading-quaternary {
  @include heading-quaternary;
}

.heading-cart {
  @include label-prototype;
  @include font-body(light);

  padding: 46px 0 0.7em;
  color: $neutral-black;
  letter-spacing: 2px;
}

.label-mobile {
  @include font-body(light);

  font-size: $type60;
  color: $neutral-black;
}



// Figures (as in numbers)
// --------------------------
@mixin heading-figure-primary {
  @include font-head;

  font-size: 1.625rem;
  font-weight: $bold;
}

.heading-figure-primary {
  @include heading-figure-primary;
}

.heading-figure-secondary {
  @include font-head;

  font-size: $type80;
  font-weight: $semibold;
}



// Subheadings
// --------------------------------------------------------
.subheading-primary {
  @include font-head;

  font-size: $type60;

  @include respond($break40) {
    font-size: $type110;
  }
}



// Signage
// --------------------------------------------------------
.sign-primary {
  @include font-head;

  margin-top: var(--sign-primary-margin-top, 0.29em);
  margin-bottom: var(--sign-primary-margin-bottom, 0.35em);
  font-size: 2rem;
  line-height: 1.078;

  @include respond($break40) {
    font-size: $sign30;
  }
}

@mixin sign-secondary {
  @include font-head;

  padding-top: 0.341em;
  padding-bottom: 0.489em;
  font-size: $sign20;
}

.sign-secondary {
  @include sign-secondary;

  // Secondary class for Shop product titles
  // abstract further if more are needed
  &.bare {
    padding-top: 0;
  }
}

.sign-tertiary {
  @include font-head;

  margin-bottom: 0.486em;
  font-size: $sign10;
}

.sign-quaternary {
  @include font-head;

  font-size: 2.45rem;
}


// Labels
// --------------------------------------------------------
.label,
.labelGray {
  @include label-prototype;

  &.padded {
    display: block;

    // Using relative em based layout within typography
    padding: 1.214em 1.057em 1.45em;
  }
}

.label-normalcase,
figcaption.label-normalcase p {
  @include label-prototype;
  @include font-body(light);

  font-weight: $light;

  // Label normalcase is only ever gray
  color: $neutral-gray60;
  text-transform: none;
  letter-spacing: inherit;
}


.label-secondary {
  @include copy-secondary;

  font-weight: $bold;

  .heading-secondary + & {
    margin-top: 0.375rem;
  }
}

.label-tertiary {
  @include label-prototype;

  font-size: $type30;
}

.label-cart-light {
  @include label-prototype;
  @include font-body(light);

  color: #000;
  text-transform: uppercase;
}


// Lists
// --------------------------------------------------------
@mixin list-dotted {
  @include copy-secondary;

  padding: 0 0 0 1em;
  margin: 0;
  line-height: 1.778;

  // Redeclared to keep appearance even if used in a nested list
  list-style-type: disc;
}

@mixin list-circled {
  @include list-unstyled;

  li {
    position: relative;
    padding-left: 2.611em;

    &::before {
      @include font-body(bold);

      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 2em;
      height: 2em;
      padding-top: 1px; // NP
      font-size: $type20;
      text-align: center;
      content: "";
      border: 3px solid $neutral-black;
      border-radius: 100%;
    }
  }
}

@mixin numbers-circled {
  @include list-circled;

  counter-reset: numbers-circled;

  li {
    &::before {
      @include font-body(bold);

      content: counter(numbers-circled);
      counter-increment: numbers-circled;
    }
  }
}
