.icon-text-grid {
  $_item-padding: min(8%, 30px);

  @include list-unstyled;

  display: grid;

  &:not(:first-child) {
    margin-top: 2.5rem;
  }

  > * {
    padding-bottom: $_item-padding;
  }

  > * + * {
    padding-top: $_item-padding;
    border-top: 1px solid $neutral-gray55;
  }

  @include respond($break30) {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    > * {
      padding-top: 0;
      border-top: none;
      border-bottom: 1px solid $neutral-gray55;

      &:nth-child(n + 3) {
        padding-top: $_item-padding;
      }

      &:nth-child(odd) {
        padding-right: $_item-padding;
        border-right: 1px solid $neutral-gray55;
      }

      &:nth-child(even) {
        padding-left: $_item-padding;
      }

      &:last-child,
      &:nth-last-child(2):nth-child(odd) {
        border-bottom: none;
      }

      &:nth-child(2n + 3) {
        position: relative;

        &::after {
          position: absolute;
          top: calc(#{$_item-padding} * -0.5);
          right: calc(#{$_item-padding} * -0.5);
          display: block;
          width: $_item-padding;
          height: $_item-padding;
          content: "";
          background-color: #fff;
        }
      }
    }
  }

  svg {
    width: min(25%, 85px);
    height: auto;

    + * {
      margin-top: 1rem;
    }
  }
}
