// Base
// ----

// Applies a natural box layout model to all the things
*,
*::before,
*::after {
  @include box-sizing(border-box);
}

// Sets text selection background color
// @include selection(#b3d4fd);

// Global
// --------------------------------------------------------
:root {
  --header-height: #{$header-height};
}

html {
  // Do you want to force grayscale antialiasing globally in webkit & gecko?
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // Do you want to enable kerning and ligatures globally in webkit & gecko?
  // Beware that this occasionally causes width:auto elements to break to multiple lines in Chrome
  // It seems that the width of the element is computed prior to kerning
  // https://code.google.com/p/chromium/issues/detail?id=189755
  // text-rendering: optimizeLegibility;
  position: relative;

  // Set the base font size here, which will correspond to 1em inside <body>
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  // Set base font-family here
  @include font-body;

  // color: $something;
  min-width: $break10;
  font-size: 1em;
  font-weight: $regular;
  line-height: $baseLineHeight;
}

// Block Level Elements
// --------------------------------------------------------
// Headings
// --------------------------
%proto-heading {
  margin: 0;
  line-height: 1;
}

h1 {
  @extend %proto-heading;

  // font-size: $alpha;
}

h2 {
  @extend %proto-heading;

  // font-size: $beta;
}

h3 {
  @extend %proto-heading;

  // font-size: $gamma;
}

h4 {
  @extend %proto-heading;

  // font-size: $delta;
}

h5 {
  @extend %proto-heading;

  // font-size: $epsilon;
}

h6 {
  @extend %proto-heading;

  // font-size: $zeta;
}


// Paragraphs
// --------------------------
@mixin copy-primary {
  @include font-body(light);

  font-size: $type60;
  line-height: 1.525;
}

p {
  @include copy-primary;

  margin: 0;

  & + & {
    @include verticalSpace(margin-top);
  }
}

// Lists
// --------------------------
ul,
ol {
  // Base list styles
}

// Remove default margins on figure
figure {
  margin: 0;
}

// Tables
// --------------------------
table {
  @include copy-primary;

  width: 100%;
}

// HR
// --------------------------
hr {
  border: 0;
  border-top: 2px solid $neutral-gray50;

  @include respond($break40) {
    border-top: 6px solid $neutral-gray50;
  }
}

// Inline Elements
// --------------------------------------------------------
// Links
// --------------------------
a {
  // Do you want links to transition smoothly between states?
  @include transition($duration color $timing);

  color: $linkColor;
  text-decoration: none;

  &:hover {
    color: $linkHover;
  }

  .rte-content &,
  .copy-small & {
    text-decoration: underline;
  }

  &[name] {
    scroll-margin-top: calc(var(--alert-bar-height, 0) + var(--header-height, #{$header-height}));

    &.exhibition-scroll-anchor {
      scroll-margin-top: calc(var(--alert-bar-height, 0) + var(--header-height, #{$header-height}) + 50px);
    }
  }
}

// Inline font modifications using separate families from font-deck
// --------------------------
b,
strong {
  @include font-body(bold);
}

i,
em {
  @include font-body(light-italic);
}

// Prefers reduced motion
// --------------------------
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important; /* stylelint-disable-line declaration-no-important */
    animation-iteration-count: 1 !important; /* stylelint-disable-line declaration-no-important */
    scroll-behavior: auto !important; /* stylelint-disable-line declaration-no-important */
  }
}
